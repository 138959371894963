import React from 'react';

function CarouselSlide({ text, img, isCurrent }) {
  return (
    <div className={isCurrent ? 'carousel-cell is-current' : 'carousel-cell'}>
      <img className="carousel-cell__img" src={img} alt="homeslide" />
      {text && (
        <div className="carousel-cell__caption">
          <div className="container container--content">
            <h2 className="carousel-cell__title">{text}</h2>
          </div>
        </div>
      )}
    </div>
  );
}
export default CarouselSlide;
