import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import '../scss/index.scss';
// hooks
import useMediaQuery from '../hooks/useMediaQuery';
// lib
import handlePageTransition from '../lib/handle-page-transition';
// components
import SEO from '../components/SEO';
import HomeContent from '../components/home/HomeContent';
import MobileHomeContent from '../components/home/HomeContentMobile';

function IndexPage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  return (
    <div>
      <SEO title="Home | Falkon Content" />
      <div className="index" style={{ overflowX: 'hidden' }}>
        {isDesktop
          ? (<HomeContent />)
          : (<MobileHomeContent />)
        }
      </div>
    </div>
  );
}

export default React.memo(IndexPage);