import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';

function SeasonalCampaignsToutMobile({ bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query HomeSeasonalCampaignsToutMobile {
      allCloudinaryMedia(
        filter: { tags: { eq: "seasonal-campaigns-tout-mobile" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  const [img01] = images;

  return (
    <SectionTrigger
      className="tout seasonal-campaigns-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        modifier="seasonal-campaigns-tout"
        title="Seasonal Campaigns"
        logo="jumpman"
      />
      <Parallax className="seasonal-campaigns-tout__img seasonal-campaigns-tout__img--1" y={scrollOffset}>
        <ParallaxImage src={img01} alt="seasonal-campaigns-tout" />
      </Parallax>
    </SectionTrigger>
  );
}

export default React.memo(SeasonalCampaignsToutMobile);
