import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';

function BestShapeToutMobile({ bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query HomeBestShapeToutMobile {
      allCloudinaryMedia(
        filter: { tags: { eq: "bestshape-tout" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  const [img01] = images;

  return (
    <SectionTrigger
      className="tout bestshape-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        // anchorLink={true}
        // link="/entertainment#bestshape"
        link="/case-studies/bestshape"
        modifier="bestshape-tout"
        title="Best shape of my life"
        logo="youtube"
      />
      <Parallax className="bestshape-tout__img bestshape-tout__img--1" y={scrollOffset}>
        <ParallaxImage src={img01} alt="" />
      </Parallax>
    </SectionTrigger>
  );
}

export default React.memo(BestShapeToutMobile);
