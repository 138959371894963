import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';

function MLBToutMobile({ bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query HomeMLBToutMobile {
      allCloudinaryMedia(
        filter: { tags: { eq: "mlb-tout-mobile" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  const [img01] = images;

  return (
    <SectionTrigger
      className="tout mlb-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        modifier="mlb-tout"
        title="I Just Wanna Play"
        link="/case-studies/mlb"
        logo="mlb"
      />
      <Parallax className="mlb-tout__img mlb-tout__img--1" y={scrollOffset}>
        <ParallaxImage src={img01} alt="" />
      </Parallax>
    </SectionTrigger>
  );
}

export default React.memo(MLBToutMobile);
