import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { string } from 'prop-types';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';
import AutoPlayVideo from '../AutoPlayVideo';
import VideoPlayer from '../VideoPlayer';

function UnbannedTout({ autoPlayVideo, bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query HomeUnbannedTout {
      allCloudinaryMedia(
        filter: { tags: { eq: "unbanned-tout" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
      allVimeo(
        filter: { name: { regex: "/UNBANNED_TOUT/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            srcset {
              link
            }
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const vimeoMedia = data.allVimeo.edges;
  const images = [];
  const videos = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  vimeoMedia.forEach(item => {
    const { srcset } = item.node;
    videos.push(srcset);
  });
  const [img01, img02] = images;
  const unbanned4 =
    'https://player.vimeo.com/external/433462433.sd.mp4?s=e6df6fb85ae5cf0b29aefc996ab265d1e559a661&profile_id=165&oauth2_token_id=1365998561';
  const unbanned4Poster = 'https://i.vimeocdn.com/video/962412827_1920x1080.jpg?r=pad'

  return (
    <SectionTrigger
      className="tout unbanned-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        modifier="unbanned-tout"
        title="Unbanned"
        subtitle="A feature documentary film"
        link="/case-studies/unbanned"
      />
      <div className="unbanned-tout__img unbanned-tout__img--1">
        <img src={img01} alt="unbanned-tout" />
      </div>
      <Parallax
        className="unbanned-tout__img unbanned-tout__img--2"
        y={scrollOffset}
      >
        <ParallaxImage src={img02} alt="unbanned-tout" />
      </Parallax>
      {
        autoPlayVideo ?
        (
          <AutoPlayVideo
            className="unbanned-tout__vid unbanned-tout__vid--1"
            poster={unbanned4Poster}
            src={unbanned4}
          />
        ) : (
        <VideoPlayer
          className="unbanned-tout__vid unbanned-tout__vid--1"
          poster={unbanned4Poster}
          src={unbanned4}
        />
        )
      }
    </SectionTrigger>
  );
}

UnbannedTout.propTypes = {
  bgColor: string,
  textColor: string,
};

export default React.memo(UnbannedTout);
