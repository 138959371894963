import React from 'react';
// components
import HomeCarouselMobile from './HomeCarouselMobile';
import RealTalkToutMobile from './RealTalkToutMobile';
import PromiselandToutMobile from './PromiselandToutMobile';
import AirMaxToutMobile from './AirmaxToutMobile';
import MLBToutMobile from './MLBToutMobile';
import UnbannedToutMobile from './UnbannedToutMobile';
import UniteCapsulesToutMobile from './UniteCapsulesToutMobile';
import TheOnesToutMobile from './TheOnesToutMobile';
// import RiseChicagoToutMobile from './RiseChicagoToutMobile';
import SeasonalCampaignsToutMobile from './SeasonalCampaignsToutMobile';
import ThePlaygroundToutMobile from './ThePlaygroundToutMobile';
import WNZ3ToutMobile from './WNZ3ToutMobile';
import XtraToutMobile from './XtraToutMobile';
import BestShapeToutMobile from './BestShapeToutMobile';
import GamePlanToutMobile from './GamePlanToutMobile';
import McEnroeToutMobile from './McEnroeToutMobile';

function IndexContentMobile() {
  return (
    <div>
      <HomeCarouselMobile
        bgColor="#A4272A"
        textColor="#fff"
       />
      <BestShapeToutMobile
        bgColor="#507d9b"
        scrollOffset={[0, 0]}
        textColor="#fff"
      />
      <GamePlanToutMobile
        bgColor="#aababf"
        scrollOffset={[0, 0]}
        textColor="#fff"
      />
      <McEnroeToutMobile
        bgColor="#080000"
        scrollOffset={[0, 0]}
        textColor="#fff"
      />
      <PromiselandToutMobile
        bgColor="#1381a2"
        scrollOffset={[0, 0]}
        textColor="#fff"
      />
      <RealTalkToutMobile
        bgColor="#000"
        scrollOffset={[-20, 0]}
        textColor="#fff"
       />
      <MLBToutMobile
        bgColor="#A4272A"
        scrollOffset={[-20, 0]}
        textColor="#fff"
       />
      <WNZ3ToutMobile
        bgColor="#f08f7c"
        scrollOffset={[0, 0]}
        textColor="#fff"
       />
      <UniteCapsulesToutMobile
        bgColor="#e1b9af"
        scrollOffset={[-20, 0]}
        textColor="#fff"
       />
      <AirMaxToutMobile
        bgColor="#eadbda"
        scrollOffset={[-20, 0]}
        textColor="#fff"
       />
      <UnbannedToutMobile
        bgColor="#5c5c5c"
        scrollOffset={[-20, 0]}
        textColor="#fff"
      />
      <TheOnesToutMobile
        bgColor="#507d9b"
        scrollOffset={[-20, 0]}
        textColor="#fff"
      />
      {/* <RiseChicagoToutMobile
        bgColor="#2f43a3"
        scrollOffset={[0, 0]}
        textColor="#fff"
      /> */}
      <ThePlaygroundToutMobile
        bgColor="#213075"
        scrollOffset={[-20, 0]}
        textColor="#fff"
      />
      <SeasonalCampaignsToutMobile
        bgColor="#18055f"
        scrollOffset={[0, 0]}
        textColor="#fff"
      />
      <XtraToutMobile
        bgColor="#0e0338"
        scrollOffset={[-20, 20]}
        textColor="#fff"
      />
    </div>
  );
}

export default React.memo(IndexContentMobile);
