import React from 'react';
// components
import HomeCarousel from './HomeCarousel';
import RealTalkTout from './RealTalkTout';
import PromiselandTout from './PromiselandTout';
import AirMaxTout from './AirmaxTout';
import MLBTout from './MLBTout';
import UnbannedTout from './UnbannedTout';
import UniteCapsulesTout from './UniteCapsulesTout';
import TheOnesTout from './TheOnesTout';
// import RiseChicagoTout from './RiseChicagoTout';
import SeasonalCampaignsTout from './SeasonalCampaignsTout';
import ThePlaygroundTout from './ThePlaygroundTout';
import WNZ3Tout from './WNZ3Tout';
import XtraTout from './XtraTout';
import BestShapeTout from './BestShapeTout';
import GamePlanTout from './GamePlanTout';
import McEnroeTout from './McEnroeTout';

function IndexContent() {
  const scrollOffset = [40, 0];
  // const scrollOffset = [0, 0];
  return (
    <div>
      <HomeCarousel bgColor="#a4272a" textColor="#fff" />
      <BestShapeTout
        bgColor="#507d9b"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <GamePlanTout
        bgColor="#aababf"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <McEnroeTout
        bgColor="#080000"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <PromiselandTout
        bgColor="#1381a2"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <RealTalkTout
        bgColor="#000"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <MLBTout
        bgColor="#A4272A"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <WNZ3Tout
        bgColor="#f08f7c"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      {/* scrollOffset={[40, -20]} */}
      <UniteCapsulesTout
        bgColor="#e1b9af"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <AirMaxTout
        bgColor="#eadbda"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <UnbannedTout
        autoPlayVideo={true}
        bgColor="#5c5c5c"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <TheOnesTout
        autoPlayVideo={true}
        bgColor="#507d9b"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      {/* <RiseChicagoTout
        autoPlayVideo={true}
        bgColor="#2f43a3"
        scrollOffset={scrollOffset}
        textColor="#fff"
      /> */}
      <ThePlaygroundTout
        autoPlayVideo={true}
        bgColor="#213075"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <SeasonalCampaignsTout
        autoPlayVideo={true}
        bgColor="#18055f"
        scrollOffset={scrollOffset}
        textColor="#fff"
      />
      <XtraTout
        bgColor="#0e0338"
        scrollOffset={[-20, 0]}
        textColor="#fff"
      />
    </div>
  );
}

export default React.memo(IndexContent);
