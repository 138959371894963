import React, { useReducer, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// components
import SectionTrigger from '../SectionTrigger';
import CarouselSlide from '../CarouselSlide';

const SLIDE_DURATION = 3000;

function HomeCarousel({ bgColor, textColor }) {
  const data = useStaticQuery(graphql`
    query HomeCarousel {
      allCloudinaryMedia(
        filter: { tags: { eq: "homepage-carousel" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.map(image => images.push(image.node.secure_url));
  const [
    carousel1,
    carousel2,
    carousel3,
    carousel4,
    carousel5,
    carousel6,
    carousel7,
  ] = images;
  const slides = [
    [carousel1, 'A CREATIVE COMPANY'],
    [carousel2, 'BORN AT THE INTERSECTION OF'],
    [carousel3, 'ADVERTISING & ENTERTAINMENT'],
    [carousel4, 'FUELED BY THE GAME'],
    [carousel5, 'INFLUENCED BY THE HUSTLE'],
    [carousel6, 'INSPIRED BY THE STREETS'],
    [carousel7, 'COMMITTED TO THE CAUSE'],
  ];

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'PROGRESS':
        case 'NEXT':
          return {
            ...state,
            takeFocus: false,
            isPlaying: action.type === 'PROGRESS',
            currentIndex: (state.currentIndex + 1) % slides.length,
          };
        case 'PREV':
          return {
            ...state,
            takeFocus: false,
            isPlaying: false,
            currentIndex:
              (state.currentIndex - 1 + slides.length) % slides.length,
          };
        case 'PLAY':
          return {
            ...state,
            takeFocus: false,
            isPlaying: true,
          };
        case 'PAUSE':
          return {
            ...state,
            takeFocus: false,
            isPlaying: false,
          };
        case 'GOTO':
          return {
            ...state,
            takeFocus: true,
            currentIndex: action.index,
          };
        default:
          return state;
      }
    },
    {
      currentIndex: 0,
      isPlaying: true,
      takeFocus: false,
    }
  );

  useEffect(() => {
    if (state.isPlaying) {
      const timeout = setTimeout(() => {
        dispatch({ type: 'PROGRESS' });
      }, SLIDE_DURATION);
      return () => clearTimeout(timeout);
    }
  }, [state.currentIndex, state.isPlaying]);

  return (
    <SectionTrigger
      className="carousel"
      bgColor={bgColor}
      textColor={textColor}
    >
      {slides.map((slide, index) => (
        <CarouselSlide
          key={index}
          isCurrent={index === state.currentIndex}
          img={slide[0]}
          text={slide[1]}
        />
      ))}
    </SectionTrigger>
  );
}

export default HomeCarousel;
