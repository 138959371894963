import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';
import AutoPlayVideo from '../AutoPlayVideo';
import VideoPlayer from '../VideoPlayer';

function SeasonalCampaignsTout({ autoPlayVideo, bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query HomeSeasonalCampaignsTout {
      allCloudinaryMedia(
        filter: { tags: { eq: "seasonal-campaigns-tout" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
      allVimeo(
        filter: { name: { regex: "/SEASONAL_CAMPAIGNS_TOUT/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            srcset {
              link
            }
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const vimeoMedia = data.allVimeo.edges;
  const images = [];
  const videos = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  vimeoMedia.forEach(item => {
    const { srcset } = item.node;
    videos.push(srcset);
  });
  const [img01, img02] = images;
  // const [vid01] = videos;
  const vid01 =
    'https://player.vimeo.com/external/433467450.sd.mp4?s=3ebcb6087f30adabe048eec9762ea07f7c4e03d2&profile_id=165&oauth2_token_id=1365998561';

  return (
    <SectionTrigger
      className="tout seasonal-campaigns-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
      <ToutCaption
        modifier="seasonal-campaigns-tout"
        title="Seasonal Campaigns"
        logo="jumpman"
      />
      <Parallax className="seasonal-campaigns-tout__img seasonal-campaigns-tout__img--1" y={scrollOffset}>
        <ParallaxImage src={img01} alt="seasonal-campaigns-tout" />
      </Parallax>
      {autoPlayVideo ?
        (
          <AutoPlayVideo
            className="seasonal-campaigns-tout__img seasonal-campaigns-tout__img--2"
            src={vid01}
          />
        ) : (
          <VideoPlayer
            className="seasonal-campaigns-tout__img seasonal-campaigns-tout__img--2"
            src={vid01}
          />
        )
      }
      <div className="seasonal-campaigns-tout__img seasonal-campaigns-tout__img--3">
        <img src={img02} alt="seasonal-campaigns-tout" />
      </div>
    </SectionTrigger>
  );
}

export default React.memo(SeasonalCampaignsTout);
