import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import ToutCaption from '../ToutCaption';

function GamePlanToutMobile({ bgColor, scrollOffset, textColor }) {
  const data = useStaticQuery(graphql`
    query HomeGamePlanToutMobile {
      allCloudinaryMedia(
        filter: { tags: { eq: "gameplan-tout" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  const [img00, img01, img02] = images;

  return (
    <SectionTrigger
      className="tout gameplan-tout"
      bgColor={bgColor}
      textColor={textColor}
    >
        <div className="gameplan-tout__img gameplan-tout__img--0">
            <img src={img00} alt="" />
        </div>
      <ToutCaption
        // anchorLink={true}
        // link="/entertainment#gameplan"
        link="/case-studies/gameplan"
        modifier="gameplan-tout"
        title="gameplan"
      />
      <Parallax className="gameplan-tout__img gameplan-tout__img--1" y={scrollOffset}>
        <ParallaxImage src={img02} alt="" />
      </Parallax>
    </SectionTrigger>
  );
}

export default React.memo(GamePlanToutMobile);
